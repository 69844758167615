import React from "react";
import "./stages.css";
import one from "../../assets/images/one.svg";
import two from "../../assets/images/two.svg";
import three from "../../assets/images/three.svg";
import four from "../../assets/images/four.svg";
import { useTranslation } from "react-i18next";

const Stages = () => {
  const { t } = useTranslation();

  return (
    <div id="stages" className="stages">
      {t("leng") === "en" ? (
        <h2>
          Development <span>Stages</span>{" "}
        </h2>
      ) : (
        <h2>
          этапы <span>разработки</span>{" "}
        </h2>
      )}
      <div className="grid none">
        <div data-aos="zoom-out-up" className="box">
          <h5 className="title right">{t("inspiring")}</h5>
          <p className="text right">{t("inspiring_text")}</p>
          <img src={one} alt="" />
        </div>
        <div data-aos="zoom-out-up" className="box">
          <h5 className="title right">{t("sophisticated")}</h5>
          <p className="text right">{t("sophisticated_text")}</p>
          <img src={two} alt="" />
        </div>
        <div data-aos="zoom-out-up" className="box">
          <h5 className="title">{t("thorough")}</h5>
          <p className="text">{t("thorough_text")}</p>
          <img className="left" src={three} alt="" />
        </div>
        <div data-aos="zoom-out-up" className="box">
          <h5 className="title">{t("continuous")}</h5>
          <p className="text">{t("continuous_text")}</p>
          <img className="left" src={four} alt="" />
        </div>
      </div>
      <div className="grid block">
        <div data-aos="flip-down" className="box">
          <h5 className="title right">{t("inspiring")}</h5>
          <p className="text right">{t("inspiring_text")}</p>
          <img src={one} alt="" />
        </div>
        <div data-aos="flip-down" className="box">
          <h5 className="title">{t("sophisticated")}</h5>
          <p className="text">{t("sophisticated_text")}</p>
          <img className="left" src={two} alt="" />
        </div>
        <div data-aos="flip-down" className="box">
          <h5 className="title right">{t("thorough")}</h5>
          <p className="text right">{t("thorough_text")}</p>
          <img src={three} alt="" />
        </div>
        <div data-aos="flip-down" className="box">
          <h5 className="title">{t("continuous")}</h5>
          <p className="text">{t("continuous_text")}</p>
          <img className="left" src={four} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Stages;
