import React, { useState } from "react";
import "./header.css";
import { Link } from "react-scroll";
import telegram from "../../assets/images/telegram_icon.png";
import en from "../../assets/images/enn.jpg";
import ru from "../../assets/images/ru.svg";
import { useTranslation } from "react-i18next";

const Header = ({ loadData }) => {
  const [lang, serLang] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    loadData(true);
    i18n.changeLanguage(lng);
    serLang(false);
  };

  return (
    <div className="header">
      <div className="head_absolute">
        <div className="container">
          <div className="between">
            <a href="/">
              <h2>
                <span>bx </span>
                team
              </h2>
            </a>
            <div className="flex">
              <Link
                to="servicies"
                smooth={true}
                duration={1000}
                className="page"
              >
                {t("services")}
              </Link>
              <Link
                to="projects"
                smooth={true}
                duration={1000}
                className="page"
              >
                {t("projects")}
              </Link>
              <Link
                to="programs"
                smooth={true}
                duration={1000}
                className="page"
              >
                {t("programs")}
              </Link>
              <Link to="stages" smooth={true} duration={1000} className="page">
                {t("stages")}
              </Link>
              <Link
                to="from_block"
                smooth={true}
                duration={1000}
                className="page"
              >
                {t("write")}
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
              }}
            >
              <div className="leng">
                <div onClick={() => serLang(!lang)} className="default">
                  {t("leng") === "en" ? (
                    <img className="flag" src={en} alt="" loading="lazy" />
                  ) : (
                    <img className="flag" src={ru} alt="" loading="lazy" />
                  )}
                </div>
                {lang && (
                  <div className="menu_lang">
                    {t("leng") === "ru" ? (
                      <img
                        onClick={() => changeLanguage("en")}
                        className="flag"
                        src={en}
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <img
                        onClick={() => changeLanguage("ru")}
                        className="flag"
                        src={ru}
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>
                )}
              </div>
              <a
                style={{ width: 45, height: 45 }}
                href="http://t.me/+996990260795"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="btn"
                  style={{ width: 45, height: 45 }}
                  loading="lazy"
                  src={telegram}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
