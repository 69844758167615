import React, { useState } from "react";
import "./form_block.css";
import arrow from "../../assets/images/arrow.svg";
import Tilt from "react-parallax-tilt";
import axios from "axios";
import Confetti from "react-dom-confetti";
import Loading from "../../UI/loading/loading";
import { useTranslation } from "react-i18next";

const FormBlock = () => {
  const [value, setValue] = useState({
    name: "",
    phone: "",
    text: "",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isConfettiActive, setConfettiActive] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const startConfetti = () => {
    setConfettiActive(true);
    setValue({ ...value, name: "", phone: "", text: "" });
    setTimeout(() => setConfettiActive(false), 2000);
  };

  const confettiConfig = {
    angle: 90,
    spread: 120,
    startVelocity: 40,
    elementCount: 50,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setPosition({ x, y });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!value.name.trim())
      newErrors.name =
        t("leng") === "en" ? "Name is required" : "Имя обязательно";
    if (!value.phone.trim()) {
      newErrors.phone =
        t("leng") === "en"
          ? "Phone number is required"
          : "Номер телефона обязателен";
    }
    if (!value.text.trim())
      newErrors.text =
        t("leng") === "en"
          ? "Description of the project is required"
          : "Описание проекта обязательно";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    try {
      setLoading(true);
      const response = await axios.post(
        "https://bxteam.vercel.app/submit",
        value
      );
      startConfetti();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="contact" className="con">
      <div className="one">
        <Confetti active={isConfettiActive} config={confettiConfig} />
      </div>
      <div className="two">
        <Confetti active={isConfettiActive} config={confettiConfig} />
      </div>
      <div className="three">
        <Confetti active={isConfettiActive} config={confettiConfig} />
      </div>
      <div className="container">
        <Tilt>
          <div
            id="from_block"
            className="from_block"
            onMouseMove={handleMouseMove}
            style={{
              "--x": `${position.x}px`,
              "--y": `${position.y}px`,
            }}
          >
            {t("leng") === "en" ? (
              <h3>
                Shall we discuss
                <br className="none" /> your <br /> project?
              </h3>
            ) : (
              <h3>
                обсудим <br className="none" /> ваш <br /> проект?
              </h3>
            )}

            <img className="arrow" src={arrow} alt="Arrow" />
            <div className="forming">
              <div
                className="custom-cursor"
                style={{
                  top: `${position.y}px`,
                  left: `${position.x}px`,
                }}
              ></div>
              <div className="input_box">
                <input
                  className={errors.name && "error"}
                  name="name"
                  value={value.name}
                  onChange={handleChange}
                  type="text"
                  placeholder={errors.name || t("name")}
                />
              </div>
              <div className="input_box">
                <input
                  className={errors.phone && "error"}
                  name="phone"
                  value={value.phone}
                  onChange={handleChange}
                  type="text"
                  placeholder={errors.phone || "+996 999 999 999 "}
                />
              </div>
              <div className="input_box">
                <input
                  className={errors.text && "error"}
                  name="text"
                  value={value.text}
                  onChange={handleChange}
                  type="text"
                  placeholder={errors.text || t("about_project")}
                />
              </div>
              <div className="btn" onClick={handleSubmit}>
                {loading ? <Loading /> : "Отправить заявку"}
              </div>
            </div>
          </div>
        </Tilt>
      </div>
    </div>
  );
};

export default FormBlock;
