import React, { useState } from "react";
import "./servicies.css";
import aika from "../../assets/images/aika.png";
import crm from "../../assets/images/crm.jpg";
import seo from "../../assets/images/seo.jpg";
import bitok from "../../assets/images/mob7-1.png";
import cargo from "../../assets/images/cargo.jpg";
import { useTranslation } from "react-i18next";

const dataRu = [
  {
    title: "Веб-разработка",
    name: "Сайты, лендинги, интернет-магазины",
    text: "Создаем впечатляющие и эффективные веб-сайты. Привлекайте клиентов и выделяйтесь среди конкурентов с нашими уникальными решениями.",
    img: cargo,
  },
  {
    title: "Мобильные приложения",
    name: "Мобильные приложения для бизнеса",
    text: "Разрабатываем интуитивные и мощные мобильные приложения. Увеличьте охват аудитории и оптимизируйте бизнес-процессы с нашими продуктами.",
    img: bitok,
  },
  {
    title: "Дизайн",
    name: "Дизайн сайтов и мобильных приложений",
    text: "Создаем привлекательный и функциональный дизайн. Эстетика, которая усиливает ваш бренд и привлекает внимание.",
    img: aika,
  },
  {
    title: "CRM-системы",
    name: "Разработка и интеграция CRM",
    text: "Интегрируем мощные CRM-системы для управления клиентами. Повышайте эффективность и качество обслуживания с нашими решениями.",
    img: crm,
  },
  {
    title: "SEO-оптимизация",
    name: "SEO-оптимизация сайтов в Google",
    text: "Увеличиваем видимость в поисковых системах. Привлекайте целевую аудиторию и занимайте топовые позиции в Google с нашей SEO-оптимизацией.",
    img: seo,
  },
];

const dataEn = [
  {
    title: "Web Development",
    name: "Websites, Landing Pages, Online Stores",
    text: "We create stunning and effective websites. Capture customer attention and stand out from the competition with our unique solutions.",
    img: cargo,
  },
  {
    title: "Mobile Applications",
    name: "Business Mobile Applications",
    text: "We develop intuitive and powerful mobile apps. Expand your audience reach and optimize business processes with our products.",
    img: bitok,
  },
  {
    title: "Design",
    name: "Website and Mobile App Design",
    text: "We craft appealing and functional designs. Visual aesthetics that enhance your brand and capture attention.",
    img: aika,
  },
  {
    title: "CRM Systems",
    name: "CRM Development and Integration",
    text: "We integrate powerful CRM systems for customer management. Improve efficiency and service quality with our solutions.",
    img: crm,
  },
  {
    title: "SEO Optimization",
    name: "SEO Optimization for Google",
    text: "We boost your visibility in search engines. Attract your target audience and secure top positions in Google with our SEO services.",
    img: seo,
  },
];

const Box = ({ el }) => {
  const [state, setState] = useState(false);

  return (
    <div
      onClick={() => setState(!state)}
      className={`saveblock ${state ? "active" : ""}`}
    >
      <div className="head_boxblock">
        <p className="titleblock">{el.title}</p>
        <div className="flexblock">
          <div className="arrowsblock"></div>
        </div>
      </div>
      {state ? (
        <div className="boxblock">
          <p className="textblock">{el.text}</p>{" "}
          <img className="image_ad" src={el.img} alt="" />
        </div>
      ) : (
        <p className="nameblock">{el.name}</p>
      )}
    </div>
  );
};

const Servicies = () => {
  const { t } = useTranslation();

  return (
    <div id="servicies" className="servicies">
      {t("leng") === "en" ? (
        <h2>
          Our <span>services</span>{" "}
        </h2>
      ) : (
        <h2>
          наши <span>услуги</span>{" "}
        </h2>
      )}

      <div className="wrapper none">
        {(t("leng") === "en" ? dataEn : dataRu).map((el, index) => (
          <div key={index} className="save">
            <div className="head_box">
              <p className="title">{el.title}</p>
              <div className="flex">
                <p className="name">{el.name}</p>
                <div className="arrows"></div>
              </div>
            </div>
            <div className="box">
              <p className="text">{el.text}</p> <img src={el.img} alt="" />
            </div>
          </div>
        ))}
      </div>
      <div className="wrapper block">
        {(t("leng") === "en" ? dataEn : dataRu).map((el, index) => (
          <Box el={el} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Servicies;
