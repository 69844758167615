import React from "react";
import "./first_block.css";
import arrow from "../../assets/images/arrow (7).svg";
import one from "../../assets/images/metTwo.png";
import two from "../../assets/images/metOne.png";
import three from "../../assets/images/metThree.png";
import elips from "../../assets/images/Ellipse.png";
import { useTranslation } from "react-i18next";

const FirstBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="first_block">
      <div className="container">
        <div className="blockx">
          {t("leng") === "en" ? (
            <h1>
              Accelerate Your <br /> <span>Business</span> with <br /> Our Fast
              <br /> <span>IT Services</span>
            </h1>
          ) : (
            <h1>
              Ускорьте свой <span>бизнес</span> с нашими <br /> быстрыми <br />
              <span>IT-услугами</span>
            </h1>
          )}
          <p className="text absolute1">{t("first_text")}</p>
          {/* <p className="text absolute2">{t("secend_text")}</p> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <a
              style={{
                position: "static",
                zIndex: 9,
                textDecorationLine: "none",
              }}
              href="http://t.me/+996990260795"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btn">
                <p>{t("discuss")}</p>
                <img loading="lazy" className="arrow" src={arrow} alt="" />
              </div>
            </a>
          </div>
          <img loading="lazy" className="image_absolute1" src={one} alt="" />
          <img loading="lazy" className="image_absolute2" src={two} alt="" />
          <img loading="lazy" className="image_box" src={elips} alt="" />
          <img loading="lazy" className="image_absolute3" src={three} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FirstBlock;
