import React from "react";
import "./programs.css";
import Slider from "react-slick";

import figma from "../../assets/images/programms (2).svg";
import code from "../../assets/images/Code.svg";
import rewind from "../../assets/images/Rewind.svg";
import python from "../../assets/images/programms (1).svg";
import Github from "../../assets/images/Property 1=Github.svg";
import NodeJs from "../../assets/images/Property 1=NodeJs.svg";
import ReactJs from "../../assets/images/Property 1=React.svg";
import Sass from "../../assets/images/Property 1=Sass.svg";
import css3 from "../../assets/images/Property 1=css3.svg";
import html5 from "../../assets/images/Property 1=html5.svg";
import javascript from "../../assets/images/Property 1=javascript.svg";
import materialui from "../../assets/images/Property 1=materialui-original.svg";
import nextjs from "../../assets/images/Property 1=nextjs-line.svg";
import redux from "../../assets/images/Property 1=redux-original.svg";
import { useTranslation } from "react-i18next";

const Programs = () => {
  const { t } = useTranslation();

  const settingsLeft = {
    className: "slider variable-width",
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: "infinite",
    cssEase: "linear",
    rtl: true,
    variableWidth: true,
  };

  const settingsRight = {
    className: "slider variable-width",
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: "infinite",
    cssEase: "linear",
    variableWidth: true,
  };

  const slides1 = [
    { img: javascript, name: "Java Script", bool: true },
    { img: code, name: "" },
    { img: Github, name: "Github" },
    { img: NodeJs, name: "NodeJs" },
    { img: ReactJs, name: "ReactJs" },
    // Add more slides here
  ];

  const slides2 = [
    { img: figma, name: "Figma", bool: true },
    { img: rewind, name: "" },
    { img: nextjs, name: "Next.js" },
    { img: Sass, name: "Sass" },
    { img: css3, name: "CSS3" },
    // Add more slides here
  ];

  const slides3 = [
    { img: html5, name: "HTML5" },
    { img: materialui, name: "MaterialUI" },
    { img: redux, name: "Redux" },
    { img: python, name: "Python", bool: true },
    { name: "Programs", program: true },
    // Add more slides here
  ];

  const renderSlides = (slides) =>
    slides.map((slide, index) => (
      <div key={index}>
        <div
          className={`program_block ${slide.bool ? "main" : ""} ${
            slide.program ? "program" : ""
          }`}
        >
          {slide?.img && (
            <img
              className={`image ${slide.name === "Java Script" ? "main" : ""}`}
              src={slide?.img}
              alt={slide.name}
            />
          )}
          {slide.name && <p>{slide.name}</p>}
        </div>
      </div>
    ));

  return (
    <div id="programs" className="programs">
      {t("leng") === "en" ? (
        <h2>
          We
          <span> use</span>
        </h2>
      ) : (
        <h2>
          мы
          <span> используем</span>
        </h2>
      )}
      <div className="wrapper">
        <div className="flex">
          <Slider {...settingsLeft}>{renderSlides(slides1)}</Slider>
        </div>
        <div className="flex">
          <Slider {...settingsRight}>{renderSlides(slides2)}</Slider>
        </div>
        <div className="flex">
          <Slider {...settingsLeft}>{renderSlides(slides3)}</Slider>
        </div>
      </div>
    </div>
  );
};

export default Programs;
