import React from "react";
import "./footer.css";
import { Link, animateScroll as scroll } from "react-scroll";
import be from "../../assets/images/color.svg";
import git from "../../assets/images/programms.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  console.log(scroll);
  return (
    <div className="footer">
      <div className="container">
        <div className="grid">
          <div>
            <h3>
              <span>BX</span> TEAM
            </h3>
            <p className="text">{t("footer_text")}</p>
            <p
              style={{
                marginTop: 42,
                fontSize: 12,
              }}
              className="text none"
            >
              © 2024 BXteam {t("rights")}
            </p>
          </div>
          <div className="trr">
            <div className="column">
              <div>
                <Link
                  to="servicies"
                  smooth={true}
                  duration={1000}
                  className="page"
                >
                  {t("services")}
                </Link>
              </div>
              <div>
                <Link
                  to="projects"
                  smooth={true}
                  duration={1000}
                  className="page"
                >
                  {t("projects")}
                </Link>
              </div>
              <div>
                <Link
                  to="programs"
                  smooth={true}
                  duration={1000}
                  className="page"
                >
                  {t("programs")}
                </Link>
              </div>
            </div>
            <div className="column">
              <div>
                <Link
                  to="stages"
                  smooth={true}
                  duration={1000}
                  className="page"
                >
                  {t("stages")}
                </Link>
              </div>
              <div>
                <Link
                  to="contact"
                  smooth={true}
                  duration={1000}
                  className="page"
                >
                  {t("write")}
                </Link>
              </div>
            </div>
          </div>
          <div className="top">
            <p className="we">{t("social")}</p>
            <div className="gred">
              <a
                target="_blank"
                href="https://www.behance.net/5334c909"
                rel="noopener noreferrer"
              >
                <img src={be} alt="Behance" />
              </a>
              <a
                target="_blank"
                href="https://github.com/bekaserkan"
                rel="noopener noreferrer"
              >
                <img src={git} alt="GitHub" />
              </a>
            </div>
          </div>
          <p
            style={{
              fontSize: 12,
            }}
            className="text block"
          >
            © 2024 BXteam {t("rights")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
