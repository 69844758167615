import { useEffect } from "react";

const useSlowScroll = (speedFactor) => {
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
      const delta = event.wheelDelta / speedFactor;
      window.scrollBy(0, -delta);
    };

    window.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [speedFactor]);
};

export default useSlowScroll;
