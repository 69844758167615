import React, { useEffect, useRef } from "react";
import "./advantages.css";
import one from "../../assets/images/22.png";
import two from "../../assets/images/23.png";
import three from "../../assets/images/25.png";
import ad from "../../assets/images/Group 5.png";
import ad_en from "../../assets/images/kucha_1_en.png";
import kucha from "../../assets/images/kucha.png";
import kucha_en from "../../assets/images/kucha_2_en.png";
import { useTranslation } from "react-i18next";

const Advantages = () => {
  const headerRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const target = headerRef.current;
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <div className="advantages">
      <div className="center">
        {t("leng") === "en" ? (
          <img className="image_ad none" src={ad_en} alt="" />
        ) : (
          <img className="image_ad none" src={ad} alt="" />
        )}
        {t("leng") === "en" ? (
          <img className="image_ad block" src={kucha_en} alt="" />
        ) : (
          <img className="image_ad block" src={kucha} alt="" />
        )}
      </div>
      <div className="line">
        <h2 ref={headerRef}>
          {t("leng") === "en" ? "Why" : "почему"} <span>Bx team?</span>
        </h2>
        <div className="lines"></div>
      </div>
      <div className="wrapper">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          className="box green"
        >
          <img src={one} alt="" />
          <div>
            <p className="title green">{t("quality")}</p>
            <p className="text green">{t("quality_text")}</p>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          className="box"
        >
          <img src={two} alt="" />
          <div>
            <p className="title">{t("speed")}</p>
            <p className="text">{t("speed_text")}</p>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          className="box"
        >
          <img src={three} alt="" />
          <div>
            <p className="title">{t("design")}</p>
            <p className="text">{t("design_text")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
